<template>
  <p>Please wait while we log you in...</p>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginFromIdentityProvider',
  mounted() {
    const code = new URL(window.location).searchParams.get('code');
    if (!code) throw new Error('No code grant in query parameters');
    this.loginViaCodeGrant({ code });
  },
  methods: {
    ...mapActions({
      loginViaCodeGrant: 'authenticate/loginViaCodeGrant',
    }),
  },
};
</script>
